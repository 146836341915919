import proj from './../Project.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Figures from '../../../Figure/Figures.js';


function JayFeatherFan() {
    return (
        <div>
            <div className={proj.subtitle}>Conservation</div>
            <div className={proj.subtext}>Condition</div>
            <div>
                <p className={proj.para}>
                    The adhesive used to secure the Jay feathers onto the foundation feather deteriorated and became brittle with the result that entire clusters of feathers came loose. Due to that, some feathers became detached and some are lost. Some feathers are bend and the barbs are disturbed. Light dust accumulation on the surfaces was given.
                </p>
                <p className={proj.para}>
                    The original ribbon is missing. Instead, a black cotton thread had been fed through the splits to hold the sticks in place.
                </p>
            </div>
            <div className={proj.subtext}>Treatment</div>
            <div>
                <p className={proj.para}>
                    The feathers were cleaned to remove dust and dirt particles. At the same time, the feathers were reshaped to remove distortions. A broken shaft was secured. Then, all loose feathers were re-adhered and detached feathers reintegrated. Missing feathers were replaced by using new Jay wing feathers.
                </p>
                <p className={proj.para}>
                    The cotton thread and old adhesive was removed from the splits. Then the fan was re-ribboned with a black silk ribbon.
                </p>
            </div>

            <div className={proj.subtitle}>Images</div>

            <Container>
                <Row xs="1" xl="2" className="justify-content-center">
                    <Figures
                        images={
                            [["JayFan/Picture38.jpg", "Before conservation"],
                            ["JayFan/Picture39.jpg", "After conservation"]]
                        }
                        caption="Before and after conservation"
                    />
                    <Figures
                        images={
                            [["JayFan/Picture40.jpg", "Detached and missing feathers"],
                                ["JayFan/Picture41.jpg", "Clusters of feathers separating from foundation feather"]]
                        }
                        caption="Jay feather fan before treatment: detached and missing feathers / clusters of feathers separating from foundation feather (or if too long: loose feathers)"
                    />
                    <Figures
                        images={
                            [["JayFan/Picture42.jpg", "Before reintegration of wing feathers"],
                                ["JayFan/Picture43.jpg", "After reintegration of wing feathers"]]
                        }
                        caption="Detail – before and after reintegration of wing feathers"
                    />
                    <Figures
                        images={
                            [["JayFan/Picture44.jpg", "Before stabilisation of broken feather shaft"],
                                ["JayFan/Picture45.jpg", "After stabilisation of broken feather shaft"]]
                        }
                        caption="Detail – before and after stabilisation of broken feather shaft"
                    />
                    <Figures
                        images={
                            [["JayFan/Picture46.jpg", "Before treatment of blue secondary wing feathers"],
                                ["JayFan/Picture47.jpg", "After treatment of blue secondary wing feathers"]]
                        }
                        caption="Detail – before and after treatment of blue secondary wing feathers"
                    />
                    <Figures
                        images={
                            [["JayFan/Picture48.jpg", "Before re-ribboning"],
                            ["JayFan/Picture49.jpg", "After re-ribboning"]]
                        }
                        caption="Detail – before and after re-ribboning"
                    />
                </Row>
            </Container>
        </div>
    );
}

export default JayFeatherFan;