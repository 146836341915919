// Title
// Image URL
// Type
// Date
// Short Description

const fans = [
    ['African Starling feather fan', 'Starling/starling2.jpg', 'Feather Fan Conservation', 'Unknown', 'A late 19th century folding fan made with feathers from the {Popup}African starling mounted over dark foundation feathers. The sticks are made of faux tortoiseshell, originally held together at the top edge by a maroon-coloured ribbon.', 28],
    ['Painted Brisé Fan', 'Brise/Picture4.jpg', 'Brise Fan Conservation', 'Unknown', 'Brisé fan with sticks made of bone. Obverse and reverse of the leaf are painted with rural landscape scenes – on the obverse with a lady on a swing and on the reverse with a windmill. The paint layer is covered with a yellowed varnish. A ribbon is holding the sticks together along the outer edge. It is painted in dark red except a painted floral section in the centre.'],
    ['Brisé fan in scalloped shape', 'Scallop/Picture20.jpg', 'Brise Fan Conservation', 'Unknown', ''],
    ['Chinese Brisé Fan', 'Chinese/Picture26.jpg', 'Chinese Brisé Fan Conservation', 'Unknown', 'This late 19th century Chinese brisé fan is made of intricately carved and pierced ivory sticks, held together by a cream-coloured ribbon.'],
    ['Jay Feather Fan', 'JayFan/Picture39.jpg', 'Feather Fan Conservation', 'Unknown', 'This is a rare feather fan made by the famous Parisian fan maker Duvelleroy for the London branch, circa 1905/10. The folding fan is made of Jay wing feathers in black, white and blue. The feathers are mounted to dark foundation feathers, which are secured onto 15 faux tortoise shell sticks.'],
    ['Mallard Duck Feather Fan', 'Mallard/Picture51.jpg', 'Feather Fan Conservation', 'Unknown', 'This feather fan is made with male Mallard duck feathers. The feathers originate from different areas of the duck. The iridescent blue feathers are the secondary feathers from the right wing. From the same wing are the feathers with black tip (Greater coverts). The other ones are breast feathers (white), neck feathers (brown) and feathers from the neck (green). The feathers are mounted to dark foundation feathers, which are secured onto 18 tortoise shell sticks.']
];
export default fans;