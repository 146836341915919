import React from 'react'; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import git from './GetInTouch.module.css';


class GetInTouch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            name: undefined,
            email: undefined,
            phone: undefined,
            address: undefined,
            subject: undefined,
            message: undefined,
            status: 'Submit'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    //const[status, setStatus] = useState("Submit");

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    /*
    handleSubmit(event) {
        alert('A name was submitted: ' + this.state.name);
        event.preventDefault();
    }*/

    handleSubmit = async (e) => {
        e.preventDefault();        
        
        this.setState({ status: "Sending..."});
        
        const { name, email, phone, address, subject, message } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            phone: phone.value,
            address: address.value,
            subject: subject.value,
            message: message.value,
        };

        try {
            //https://api.fansandfeathers.com/contact
            //http://192.168.64.2:5000/contact
            let response = await fetch("https://api.fansandfeathers.com/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(details),
            });            

            let result = await response.json();
            /*alert(result.status);*/
            if ( result.status === "Message Sent" )
            {
                this.setState({ status: "Thank you, message sent" });
            } else {
                alert(result.status);
                this.setState({ status: "Resubmit" });
            }
        } catch (error) {
            alert(error);
            this.setState({ status: "Submit" });
        }        
    };

    render() {
     
        return (
            <div>
                <div>&nbsp;</div>            
                <Container fluid>
                    <Row className={git.form}>
                        <Col lg={6}>
                            <div>
                                <h2>GET IN TOUCH</h2>
                            </div>
                            <div>
                                <p>Edinburgh, Scotland, UK</p>
                            </div>
                            <div>
                                <p><a style={{color:"inherit", "textDecoration":"none"}} href="mailto:info@fansandfeathers.com">info@fansandfeathers.com</a></p>
                            </div>
                            <div>
                                <p>0131-358-1727</p>
                            </div>
                        </Col>
                        <Col>
                            <div id={git.spacer}></div>
                            <form onSubmit={this.handleSubmit}>
                                <Container>
                                    <Row xs={1} sm={2}>
                                        <Col>
                                            <div className={git.inputdiv}>
                                                <label>Name</label>
                                                <div>
                                                    <input id={git.name} name="name" value={this.state.name} onChange={this.handleChange} className={git.input} type="text" placeholder="Enter your name" required={this.state.name != null} aria-required maxLength="100" aria-invalid="false" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className={git.inputdiv}>
                                                <label>
                                                    Email                                                
                                                </label>
                                                <div>                                                
                                                    <input id={git.email} name="email" value={this.state.email} className={git.input} type="email" placeholder="Enter your email" required={this.state.email != null} aria-required="true" pattern="^.+@.+\.[a-zA-Z]{2,63}$" maxLength="250"></input>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className={git.inputdiv}>
                                                <label>
                                                    Phone
                                                </label>
                                                <div>
                                                    <input id={git.phone} name="phone" value={this.state.phone} className={git.input} type="tel" placeholder="Enter your phone number" required={this.state.phone != null} aria-required="true" maxLength="50" aria-invalid="false" />                                                    
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className={git.inputdiv}>
                                                <label>
                                                    Address
                                                </label>
                                                <div>
                                                    <input id={git.address} name="address" value={this.state.address} className={git.input} type="text" placeholder="Enter your address" aria-required="false" maxLength="250" aria-invalid="false" />                                                    
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row xs={1}>
                                        <Col>
                                            <div className={git.inputdiv}>
                                                <label>
                                                    Subject
                                                </label>
                                                <div>
                                                    <input id={git.subject} name="subject" value={this.state.subject} className={git.input} type="text" placeholder="Type the subject" aria-required="false" maxLength="250" aria-invalid="false" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className={git.textareadiv}>
                                                <label>
                                                    Message
                                                </label>
                                                <div>
                                                    <textarea id={git.message} name="message" value={this.state.message} className={git.textarea} type="text" placeholder="Type your message here..." aria-required="false" aria-invalid="true" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                        <div>
                                            <button className={git.button}>
                                                {this.state.status}
                                            </button>
                                        </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </form>                        
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default GetInTouch;