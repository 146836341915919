import map from './Map.module.css';

function Map() {
    return (
        <div>
            <iframe className={map.map}
                title="map"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDwBQMUIYA9MKiRY6j-CbXuir5Q-mYmPgs&q=Edinburgh"
                height="470"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">                
            </iframe>            
        </div>        
    );
}

export default Map;

/*
"https://www.google.com/maps/embed/v1/place?key=AIzaSyDwBQMUIYA9MKiRY6j-CbXuir5Q-mYmPgs&q=Edinburgh"


<iframe className={map.map}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d71502.930617714!2d-3.2753773181439785!3d55.94128462691096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4887b800a5982623%3A0x64f2147b7ce71727!2sEdinburgh!5e0!3m2!1sen!2suk!4v1669319981146!5m2!1sen!2suk"                
                height="470"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
            */