import proj from './../Project.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Figures from '../../../Figure/Figures.js';


function MallardFan() {
    return (
        <div>
            <div className={proj.subtitle}>Conservation</div>
            <div className={proj.subtext}>Condition</div>
            <div>
                <p className={proj.para}>
                    The adhesive used to secure the Mallard duck feathers onto the foundation feathers deteriorated and became brittle with the result that entire clusters of feathers came loose. Due to that, some feathers became detached, and some are lost. Some feathers are bend and the barbs are disturbed. Furthermore, the feathers are very dusty and the old adhesive turned brown and left obtrusive stains on the feathers.
                </p>
                <p className={proj.para}>
                    The ribbon is split in several places
                </p>
            </div>
            <div className={proj.subtext}>Treatment</div>
            <div>
                <p className={proj.para}>
                    The feathers were cleaned to remove dust and brown adhesive residues. At the same time, the feathers were reshaped to remove distortions. Then, all loose feathers were re-adhered and detached feathers reintegrated. Missing feathers were replaced by using new Mallard duck feathers.
                </p>
                <p className={proj.para}>
                    The old ribbon and remaining adhesive was removed and the fan was re-ribboned.
                </p>
            </div>

            <div className={proj.subtitle}>Images</div>

            <Container>
                <Row xs="1" xl="2" className="justify-content-center">
                    <Figures
                        images={
                            [["Mallard/Picture50.jpg", "Before conservation"],
                            ["Mallard/Picture51.jpg", "After conservation"]]
                        }
                        caption="Before and after conservation of Mallard duck fan"
                    />
                    <Figures
                        images={
                            [["Mallard/Picture52.jpg", "Stick 1 before cleaning, reshaping, stabilisation and reintegration of replacement feathers"],                            
                                ["Mallard/Picture54.jpg", "Stick 1 after cleaning, reshaping, stabilisation and reintegration of replacement feathers"]]
                        }
                        caption="Stick 1 before and after cleaning, reshaping, stabilisation and reintegration of replacement feathers"
                    />
                    <Figures
                        images={
                            [["Mallard/Picture55.jpg", "Stick 7 before cleaning, reshaping, stabilisation and reintegration of replacement feathers"],
                                ["Mallard/Picture56.jpg", "Stick 7 after cleaning, reshaping, stabilisation and reintegration of replacement feathers"]]
                        }
                        caption="Stick 7 before and after cleaning, reshaping, stabilisation and reintegration of replacement feathers"
                    />
                    <Figures
                        images={
                            [["Mallard/Picture57.jpg", "Detail of Stick 10 before cleaning, reshaping, stabilisation and reintegration of replacement feathers"],
                                ["Mallard/Picture58.jpg", "Detail of Stick 10 after cleaning, reshaping, stabilisation and reintegration of replacement feathers"]]
                        }
                        caption="Detail of Stick 10 before and after cleaning, reshaping, stabilisation and reintegration of replacement feathers"
                    />                    
                    <Figures
                        images={
                            [["Mallard/Picture62.jpg", "Right hand side of fan before treatment"],
                                ["Mallard/Picture63.jpg", "Right hand side of fan after treatment"]]
                        }
                        caption="Right hand side of fan before and after treatment"
                    />
                    <Figures
                        images={
                            [["Mallard/Picture61.jpg", "Body and wing feathers"],
                                ["Mallard/Picture60.jpg", "Two prepared wing feathers"]]
                        }
                        caption="Replacement feathers: body and wing feathers and two prepared wing feathers"
                    />
                </Row>
            </Container>
        </div>
    );
}

export default MallardFan;