import soon from './ComingSoon.module.css';


function ComingSoon() {
    return (
        <div id={soon.outer}>
            <div className='centre'>
                <h2>Coming Soon</h2>
                <p>This part of the website is not yet finished, but will be coming soon...</p>
            </div>
        </div>
    );
}

export default ComingSoon;