import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState } from "react";
import './Menu.css';


function Menu() {
    const [expanded, setExpanded] = useState(false);
    return (
        <Navbar expanded={expanded} className="color-nav" expand="md">
            <Container>
                <Navbar.Brand href="/" className="font_playfair_22">FANS &amp; FEATHERS</Navbar.Brand>                
                <Navbar.Toggle
                    aria-controls="navbarScroll"
                    onClick={() => setExpanded(expanded ? false : "expanded")}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>Home</Nav.Link>
                        <Nav.Link as={Link} to="/about" onClick={() => setExpanded(false)}>About</Nav.Link>                    
                        <Nav.Link as={Link} to="/cv" onClick={() => setExpanded(false)}>CV</Nav.Link>
                        <Nav.Link as={Link} to="/contact" onClick={() => setExpanded(false)}>Contact</Nav.Link>
                        <Nav.Link as={Link} to="/services" onClick={() => setExpanded(false)}>Services</Nav.Link>
                        <Nav.Link as={Link} to="/blog" onClick={() => setExpanded(false)}>Blog</Nav.Link>
                        <Nav.Link as={Link} to="/portfolio" onClick={() => setExpanded(false)}>Portfolio</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Menu;

/*
<Navbar.Toggle aria-controls="basic-navbar-nav" />
*/