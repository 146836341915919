import proj from './../Project.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Figures from '../../../Figure/Figures.js';


function ChineseBriseFan() {
    return (
        <div>
            <div className={proj.subtitle}>Conservation</div>
            <div className={proj.subtext}>Condition</div>
            <div>
                <p className={proj.para}>                    
                    The fan is incomplete – it is currently made of 20 sticks, although the design and similar fans give evidence that it is missing at least one stick (no.15) and both guards.  The sticks are all covered in dust particles.
                </p>
                <p className={proj.para}>The rivet pin is missing. In its place, two entwined metal wires were used to secure the end of the fan.
                    </p>
                    <p className={proj.para}>
                    A cream-coloured ribbon in the width of 6mm runs through the 4mm wide slots in the sticks. This lead to folds in the material and a bad fit. Furthermore, the ribbon showed several splits and separations in 3 areas, with more areas vulnerable to come apart.
                </p>
            </div>
            <div className={proj.subtext}>Treatment</div>
            <div>
                <p className={proj.para}>
                    Dust particles from the carved sticks were removed.
                    The wires were removed and replaced with a rivet pin, recycled from another broken fan. This involved careful hammering to remove and reinsert the ivory ring without damaging it.
                </p>
                <p className={proj.para}>
                    The ribbon was replaced with a new one in the same colour but with a width of 4mm, which is a better fit and imitation of the historic ribbon. Strips of ribbon had been used instead of a continuous ribbon due to the type of fan, weight of sticks and thickness of ribbon.
                </p>
            </div>

            <div className={proj.subtitle}>Images</div>

            <Container>
                <Row xs="1" xl="2" className="justify-content-center">
                    <Figures
                        images={
                            [["Chinese/Picture25.jpg", "Before conservation"],
                            ["Chinese/Picture26.jpg", "After conservation"]]
                        }
                        caption="Obverse of ivory fan with broken ribbon before and after conservation"
                    />
                    <Figures
                        images={
                            [["Chinese/Picture27.jpg", "Detail – fan with broken ribbon before conservation"],
                            ["Chinese/Picture28.jpg", "Detail - fan after conservation – surface cleaned and new ribbon inserted"]]
                        }
                        caption="Detail of fan before conservation and after conservation"
                    />
                    <Figures
                        images={
                            [["Chinese/Picture29.jpg", "Detail - broken ribbon which is too wide and soiling on surfaces"],
                            ["Chinese/Picture30.jpg", "Detail – new ribbon in adequate width; dust accumulation reduced"]]
                        }
                        caption="Detail of fan before conservation and after conservation"
                    />
                    <Figures
                        images={
                            [["Chinese/Picture31.jpg", "Before dust removal"],
                            ["Chinese/Picture32.jpg", "After dust removal"]]
                        }
                        caption="Dust accumulation on surfaces"
                    />
                    <Figures
                        images={
                            [["Chinese/Picture33.jpg", "Before conservation"],
                            ["Chinese/Picture34.jpg", "After conservation"]]
                        }
                        caption="Previous repair with wire to replace missing pivot pin – new/ recycled pivot pin"
                    />
                    <Figures
                        images={
                            [["Chinese/Picture35.jpg", "Before conservation"],                            
                            ["Chinese/Picture37.jpg", "After conservation"]]
                        }
                        caption="Previous repair with wire to replace missing pivot pin – new/ recycled pivot pin"
                    />
                </Row>
            </Container>
        </div>
    );
}

export default ChineseBriseFan;