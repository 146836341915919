import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from './components/Menu/Menu';
import Home from './components/Home/Home';
import About from './components/About/About';
import CV from './components/CV/CV';
import Contact from './components/Contact/Contact';
import Portfolio from './components/Portfolio/Portfolio';
import Project from './components/Portfolio/Project/Project';
import ComingSoon from './components/ComingSoon/ComingSoon';
import NotFound from './components/NotFound/NotFound';
import Footer from './components/Footer/Footer';
import './App.css';


function App() {  
  return (
    <div>      
      <Router>
        <Menu />
        <Routes>               
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/cv" element={<CV />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<ComingSoon />} />
          <Route path="/blog" element={<ComingSoon />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/project" element={<Project />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>    
    </div>    
  );
}

export default App;
