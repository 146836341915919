import port from './Portfolio.module.css';
import { Link } from 'react-router-dom';
import fans from './Project/projectData.js';

function Portfolio() {

    const buildURL = imagePath => `/img/${imagePath}`

    return (
        <div>
            <div id={port.intro}>
                <h1 id={port.h1}>My Portfolio</h1>
                <span className="font_didot_20">Welcome to my portfolio. Here you’ll find a selection of my work. Explore my projects to learn more about what I do.</span>
            </div>

            <div className={port.container}>
                {fans.map((fan, i) => {                           
                    return (
                    <div className={port.item}>                        
                        <Link to= "/project" state = {{ startIndex: i} }>
                            <div className={port.box}>                            
                                <figure className={port.myDIV}>
                                    <img className={port.image} alt={fan[0]} src={buildURL(fan[1])}></img>
                                </figure>
                                <div className={`${port.label} ${port.hide}`}>
                                    {fan[0]}
                                </div>                        
                            </div>                
                        </Link>                          
                    </div>                    
                )})}                
            </div>

        </div>
        
    );
}

export default Portfolio;

// <Link to={ `/project/${i}` } x = {i}>
// <Link to={ "/project" } state = {{ project: fan[0], url: fan[1], index: i}}>
/*
<div className="container cont">
                <div className="row row-cols-xs-1 row-cols-md-2 row-cols-lg-3 g-5">
                    {fans.map(fan => (
                        <div className="col">
                            <div className="box">
                                <figure>                                    
                                    <img className="image" alt={fan[0]} src={buildURL(fan[1])}></img>
                                </figure>
                                <div className="port">
                                    {fan[0]}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>




<div>
            <div id={portfolio.intro}>
                <h1 id={portfolio.h1}>My Portfolio</h1>
                <span className="font_didot_20">Welcome to my portfolio. Here you’ll find a selection of my work. Explore my projects to learn more about what I do.</span>
            </div>

            <Container className={portfolio.debugborder} >
                <Row xs={1} md={2} lg={3} className="row justify-content-center" style={{"background-color": "red"}}>
                    {images.map(image => (
                        <Col className="d-flex justify-content-center">
                            <figure className={portfolio.f}>
                                <img className={portfolio.image} src={buildURL(image)}></img>
                            </figure>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
*/