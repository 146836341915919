import Title from './Title/Title';
import GetInTouch from '../Contact/GetInTouch/GetInTouch';
import Map from '../Contact/Map/Map';


function Home() {
    return (
        <div>
            <Title />
            <GetInTouch />
            <Map />
        </div>
    );
}

export default Home;