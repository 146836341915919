import proj from './../Project.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Figures from '../../../Figure/Figures.js';


function BriseFan() {
    return (
        <div>
            <div className={proj.subtitle}>Conservation</div>
            <div>               
                <p className={proj.para}>
                    The ribbon started to come loose and showed multiple splits, distortions and loss in the paint layer. The splits were secured and mended with strips of Japanese tissue precoated with an acrylic adhesive. Each strip was adhered by reactivating the adhesive with a hot spatula. During the stabilisation it became obvious that more areas are split than assumed. In total, around 26 splits had been secured. Some of the strips had to be reinforced by applying further adhesive.
                </p>
                <p className={proj.para}>
                    The misalignment of the ribbon in one area caused by an earlier intervention was undone by removing the old adhesive with acetone. The ribbon was re-adhered in this area and in all other loose areas with a protein-based adhesive.
                </p>
                <p className={proj.para}>
                    Where paint losses in the ribbon were very obtrusive, the losses were lightly retouched with acrylic paint.
                </p>
            </div>

            <div className={proj.subtitle}>Images</div>

            <Container>
                <Row xs="1" xl="2" className="justify-content-center">
                    <Figures
                        images={
                            [["Brise/Picture3.jpg", "Before conservation"],
                            ["Brise/Picture4.jpg", "After conservation"]]
                        }
                        caption="Obverse of brise fan with broken red ribbon before and after conservation"
                    />
                    <Figures
                        images={
                            [["Brise/Picture5.jpg", "Before conservation"],
                            ["Brise/Picture6.jpg", "After conservation"]]
                        }
                        caption="Reverse of brise fan with broken red ribbon before and after conservation"
                    />
                    <Figures
                        images={
                            [["Brise/Picture7.jpg", "Before conservation"],
                            ["Brise/Picture8.jpg", "After conservation"]]
                        }
                        caption="Detail of middle section of broken painted ribbon, before and after conservation"
                    />
                    <Figures
                        images={
                            [["Brise/Picture9.jpg", "Before conservation"],
                            ["Brise/Picture10.jpg", "After conservation"]]
                        }
                        caption="Detail of lifting, distorted and splitting ribbon - obverse, before and after conservation"
                    />
                    <Figures
                        images={
                            [["Brise/Picture11.jpg", "Before conservation"],
                            ["Brise/Picture12.jpg", "After conservation"]]
                        }
                        caption="Lifting delaminating ribbon and abraded paint layer (reverse, before and after conservation)"
                    />
                    <Figures
                        images={
                            [["Brise/Picture13.jpg", "Before conservation"],
                            ["Brise/Picture14.jpg", "After conservation"]]
                        }
                        caption="Misaligned section of ribbon due to previous intervention and abraded paint – before and after conservation"
                    />
                    <Figures
                        images={
                            [["Brise/Picture15.jpg", "Before conservation"],
                            ["Brise/Picture16.jpg", "After conservation"]]
                        }
                        caption="Example for splits and abrasion on ribbon – before and after conservation"
                    />
                    <Figures
                        images={
                            [["Brise/Picture17.jpg", "Before conservation"],
                            ["Brise/Picture18.jpg", "After conservation"]]
                        }
                        caption="Side view of ribbon in closed position of fan – before and after conservation"
                    />
                </Row>
            </Container>
        </div>
    );
}

export default BriseFan;