import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import about from './About.module.css';
import { Link } from 'react-router-dom';


function About() {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className={about.container}>
                        <figure>
                            <img id={about.lydiaphoto} src="img/IMG_8494.jpg" alt="Lydia"></img>
                        </figure>                    
                        <div className="centre">                        
                            <h2 className="white">
                                <span>ABOUT</span>
                            </h2>
                        </div>
                    </div>
                </Col>               
            </Row>
            <Row>
                <Col>
                    <div>
                        <div id={about.biotitle}>
                            <h4>About Me</h4>
                        </div>
                        <div>
                            <a href="https://www.conservationregister.com/acr-profile-page/2134/"><img id={about.iconimg} src="/img/ICON-accredited-member.jpg" alt="Accredited Member"></img></a>
                        </div>
                        <div>
                            <p>
                                Hi, I'm Lydia.  I work as employee at a National Museum, and also as a freelance conservator. Both roles give me the opportunity to work on exciting projects, with a wide range of objects and materials.
                            </p><p>
                                My specialisation is organic materials. I especially enjoy working with feathers. This interest led me towards the collection and preservation of a variety of folding fans, and joining the <a style={{color:"inherit"}} href="https://fancircleinternational.org/">Fan Circle International</a>.
                            </p><p>
                                More recently I have extended my expertise in project management. In the past few years I have managed larger projects, both as an employee and a freelancer. The combination of these roles has given me the opportunity to grow and develop new skills in exhibition management and collections care. 
                            </p>
                        </div>
                        <div id={about.getintouch}>
                            <p>
                                <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>Get in Touch</Link>
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default About;