import footer from './Footer.module.css';


function Footer() {
    return (
        <div>
            <div id={footer.title}>
                <p className="font_playfair_22">
                    Fans &amp; Feathers
                </p>
            </div>
            <div id={footer.email}>
                <p className="font_raleway_15">
                    <a style={{ color: "inherit", "textDecoration": "none" }} href="mailto:info@fansandfeathers.com">info@fansandfeathers.com</a>
                </p>
            </div>
            <div id={footer.copyright}>
                <p className="font_raleway_14">
                    ©2022-24 Fans &amp; Feathers.  Website created by Campbell Wild
                </p>
            </div>
        </div>
    );
}

export default Footer;