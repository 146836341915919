import proj from './../Project.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Figures from '../../../Figure/Figures.js';


function ScallopFan() {
    return (
        <div>
            <div className={proj.subtitle}>Conservation</div>
            <div>
                <p className={proj.para}>
                    Brisé fan in scalloped shape, sticks are made of bone and decorated with floral design with green, red, blue and gold paint. The sticks were held together by a 6mm wide silk ribbon in dark green colour. The rivet pin is decorated with pink stone in bezel setting on each side.
                </p>
                <p className={proj.para}>
                    The ribbon showed numerous splits which lead in a separation of the sticks. Furthermore, the dark green colour of the ribbon is obscuring the painted decoration on the sticks. Therefore, the ribbon was replaced with a new silk ribbon in a sage green colour, as this best matches the painting.
                </p>
            </div>

            <div className={proj.subtitle}>Images</div>

            <Container>
                <Row xs="1" xl="2" className="justify-content-center">
                    <Figures
                        images={
                            [["Scallop/Picture19.jpg", "Before conservation"],
                            ["Scallop/Picture20.jpg", "After conservation"]]
                        }
                        caption="Obverse of scalloped fan with broken green ribbon before conservation"
                    />
                    <Figures
                        images={
                            [["Scallop/Picture21.jpg", "Before conservation"],
                            ["Scallop/Picture22.jpg", "After conservation"]]
                        }
                        caption="Reverse of scalloped fan with broken green ribbon before conservation"
                    />
                    <Figures
                        images={
                            [["Scallop/Picture23.jpg", "Before conservation"],
                            ["Scallop/Picture24.jpg", "After conservation"]]
                        }
                        caption="Broken green ribbon is causing separation of sticks  "
                    />
                </Row>
            </Container>
        </div>
    );
}

export default ScallopFan;