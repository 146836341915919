function CV() {
    return (
        <div>
            <div>
                <h2>RESUME</h2>            
                <h4>Professional Experience</h4>
                <p>Below you will find a comprehensive collection of my professional experiences. Each position has been an important part of my career, and provided important tools to develop both personally and professionally. If you’re interested in references or learning more about my background, feel free to contact me directly.</p>
            </div>
            <div>
                <h3>Conservator at National Museums Scotland</h3>
                <h4>December 2019 - Current</h4>
                <p>TBC</p>
            </div>
            <div>
                <h3>Assistant Conservator at National Museums Scotland (part time)</h3>
                <h4>June 2015 - November 2019</h4>
                <p>Assessment and conservation of objects for new Ancient Egyptian and East Asian gallery; refurb of 8 galleries in 2016: lead conservator for material stream 'polychrome wooden objects and organics', conservation of organic/ inorganic objects; treatment of objects for special exhibitions; assessment/ conservation/packing and couriering of national and international outgoing loans and touring exhibitions; condition check incoming loans</p>
            </div>
            <div>
                <h3>Freelance project conservator for collections move project</h3>
                <h4>July 2018 - March 2019</h4>
                <p>Assessment of condition and identification of hazards in the Scientific Instruments Collection of the Hunterian Museum Glasgow. Coordination of packing and move to new store. Supervision of employees and contractors. Key points: prioritisation of tasks, setting up risk assessments and safe work environment, seamless documentation and tracking of progress, good communication with project team and working towards tight deadline.</p>
            </div>
            <div>
                <h3>Guest lecturer at University of Gothenburg</h3>
                <h4>April 2018 - May 2018</h4>
                <p>I delivered four 1-week-courses to 2nd year students at Institutionen för kulturvård: conservation of Basketry and Birchbark, Bark cloth, Feather work and Skeletal Materials. Topics: biology, chemistry and ID of materials; ID of handcraft techniques and deterioration processes; treatment methods; ethical judgement / decision making in conserving ethnographic objects; pesticide awareness; preventive measures. Teaching: lectures and practical sessions.</p>
            </div>
            <div>
                <h3>Voluntary work at GRASSI Museum für Völkerkunde in Leipzig</h3>
                <h4>March 2015 - May 2015</h4>
                <p>Germany Project: Conservation & mounting of objects for exhibition 'Woven time'; assessment, treatment, mounting and packing of loans (material: plant fibres, shells)</p>
            </div>
        </div>
    );
}

export default CV;