import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import proj from './Project.module.css';
import fans from './projectData.js';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';

import AfricanStarlingFan from './Projects/AfricanStarlingFan';
import BriseFan from './Projects/BriseFan';
import ScallopFan from './Projects/ScallopFan';
import ChineseBriseFan from './Projects/ChineseBriseFan';
import JayFeatherFan from './Projects/JayFeatherFan';
import MallardFan from './Projects/MallardFan';


function Project() {
    const { state } = useLocation();

    var startIndex = state?.startIndex;    
    const [allValues, setAllValues] = useState({
        projIndex: startIndex,
        show: false
    });
    var index = allValues?.projIndex;
    const show = allValues?.show;

    if (index === undefined) { index = 0; }

    //const changeHandler = e => {
    //    setAllValues( prevValues => {
    //        return { ...prevValues, [e.target.name]: e.target.value}
    //    })
    //}


    const handleClose = () => setAllValues({ ...allValues, show: false });
    const handleShow = () => setAllValues({...allValues, show: true});

    const currProj = fans[index];
    const prevProj = fans[index - 1];
    const nextProj = fans[index + 1];

    const title = currProj[0];
    const url = currProj[1];
    const type = currProj[2];
    const date = currProj[3];
    const desc = currProj[4];
    const dim = currProj[5];
       
    const getDesc = () => {
        switch (title) {
            case 'African Starling feather fan':
                return <>A late 19th century folding fan made with feathers from the&nbsp;
                <Link onClick={handleShow}>African starling</Link>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton />                        
                        <Card>
                            <Card.Img variant="top" src="/img/Starling/starling.png" />
                            <Card.Body>
                                <Card.Title>Lamprotornis (African Starling)</Card.Title>
                                <Card.Text>
                                    A glossy starling native to southern Africa.  This example is the Greater blue-eared starling (see <Link to="https://en.wikipedia.org/wiki/Lamprotornis">Wikipedia</Link>)
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Modal>
                &nbsp;mounted over dark foundation feathers. The sticks are made of faux tortoiseshell, originally held together at the top edge by a maroon-coloured ribbon.</>;

            case 'Jay Feather Fan':
                return <>This is a rare feather fan made by the famous Parisian fan maker Duvelleroy for the London branch, circa 1905/10. The folding fan is made of&nbsp;
                    <Link onClick={handleShow}>Jay</Link>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton />
                        <Card>
                            <Card.Img variant="top" src="/img/JayFan/jay.jpg" />
                            <Card.Body>
                                <Card.Title>Garrulus Glandarius (Eurasian Jay)</Card.Title>
                                <Card.Text>
                                    Three types of wing feathers are present: the top ones are primary feathers (only 4 feathers per wing), the blue and the black feathers are secondary wing feathers. Here again, one wing does only have 14 blue feathers. Each stick has ca. 40 of such blue feathers and ca. 8 primary wing feathers (4 of the left wing and 4 of the right wing (see <Link to="https://upload.wikimedia.org/wikipedia/commons/6/6d/Garrulus_glandarius_1_Luc_Viatour.jpg">Wikipedia</Link>)
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Modal>
                 &nbsp;wing feathers in black, white and blue. The feathers are mounted to dark foundation feathers, which are secured onto 15 faux tortoise shell sticks.</>

            default:                    
                return desc;
        }
    }


    var prevLink = '';
    if (prevProj !== undefined) { prevLink = prevProj[0]; }

    var nextLink = '';
    if (nextProj !== undefined) { nextLink = nextProj[0]; }

    const buildURL = url => `/img/${url}`

    const navigate = useNavigate();
    function handleClick(fwdOrBack) {
        var newIndex = index + fwdOrBack;
        
        if (newIndex > -1 && newIndex < fans.length)
        {            
            setAllValues({ ...allValues, projIndex: index + fwdOrBack});
        }
    }

    const renderProject = () => {
            switch (title) {
                case 'African Starling feather fan':
                    return <AfricanStarlingFan />
                case 'Painted Brisé Fan':
                    return <BriseFan />
                case 'Brisé fan in scalloped shape':
                    return <ScallopFan />
                case 'Chinese Brisé Fan':
                    return <ChineseBriseFan />
                case 'Jay Feather Fan':
                    return <JayFeatherFan />
                case 'Mallard Duck Feather Fan':
                    return <MallardFan />
                default:                    
                    return "TBC..." //null;
        }
    }

    const dimensions = () => {                
        if (dim > 0) {
            return <div>
                <div className={proj.subtitle}>Dimensions</div>
                <div className={proj.subtext}>{dim} centimeters</div>
            </div>;
        }        
    }


    /*<div className={proj.subtitle}>Project Date</div>
                        <div className={proj.subtext}>{date}</div>
                        */
    return (
        <div>                   
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className={proj.name}>{title}</div>
                    </div>
                </div>
                <div className="row align-items-center row-cols-1 row-cols-md-2 ">
                    <div className="col">                        
                        <div className={proj.subtitle}>Project Type</div>
                        <div className={proj.subtext}>{type}</div>                        
                        {dimensions()}
                    </div>
                    <div className="col">
                        <div className={proj.subtext}>
                            {getDesc()}
                        </div>
                    </div>
                </div>
                <p></p>
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <figure>
                                <img className={proj.image} alt={title} src={buildURL(url)}></img>
                            </figure>
                        </div>
                    </div>
                    <div className="col-12">
                       {renderProject()}
                    </div>
                </div>
                <p></p>
                <div className="row">
                    <div className="col">
                        <div className={proj.previous}>                            
                            <button className={proj.button} type="button" disabled={index === 0} onClick={() => handleClick(-1)}><b>&lt;</b> Previous Project</button>
                        </div>
                    </div>
                    <div className="col"> 
                        <div className={proj.next}>
                            <button className={proj.button} type="button" disabled={index === fans.length - 1} onClick={() => handleClick(1)}>Next Project <b>&gt;</b></button>
                        </div>                       
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Project;
