import GetInTouch from './GetInTouch/GetInTouch';
import Map from './Map/Map';


function Contact() {
    return (
        <div>
            <GetInTouch />
            <Map />
        </div>
    );
}

export default Contact;