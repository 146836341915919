import Figure from 'react-bootstrap/Figure';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';

import fig from './Figures.module.css';


function Figures({images, caption}) {
    const [show, setShow] = useState(0);

    const handleClose = () => setShow(0);
    const handleShow = (i) => setShow(i);

    const width = 480 / images.length;

    
/*
<Figure>
                {images.map((imgAltPair, index) => 
                {
                    var showIndex = index + 1;                    
                    return (
                        <>
                            <Figure.Image className={showIndex === 1 ? fig.figurefirst : fig.figure} style={{cursor: 'pointer'}} onClick={() => handleShow(showIndex)} width={width} alt={imgAltPair[1]} src={"/img/" + imgAltPair[0]} />
                            <Modal size='xl' centered='true' show={show === showIndex} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{imgAltPair[1]}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>                                   
                                    <img width='100%' src={"/img/" + imgAltPair[0]} />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    )
                }                                 
                              
                )}
                <Figure.Caption className={fig.caption}>{caption}</Figure.Caption>
            </Figure>
*/

/*

*/

    const handleSelect = (selectedIndex) => {
        setShow(selectedIndex + 1);
    };

    var oImgAltPair = images[show-1];
    var imgTitle = "";    
    if (oImgAltPair !== undefined) { imgTitle = oImgAltPair[1]; }

    return (
        <Col className="text-center my-auto">
            <Figure>                
                {images.map((imgAltPair, index) => 
                {
                    var showIndex = index + 1;
                    return (
                        <>
                            <Figure.Image className={showIndex === 1 ? fig.figurefirst : fig.figure} style={{ cursor: 'pointer' }} 
                                onClick={() => handleShow(showIndex)} width={width} alt={imgAltPair[1]} src={"/img/" + imgAltPair[0]} />                           
                        </>
                    )
                }                                                    
                )}
                <Figure.Caption className={fig.caption}>{caption}</Figure.Caption>                                
            </Figure>
            <Modal size='xl' centered='true' show={show > 0} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><div className="modalTitle">{imgTitle}</div></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel activeIndex={show - 1} onSelect={handleSelect} interval={null} data-bs-theme="dark" wrap={false} indicators={false}>
                        {images.map((imgAltPair, index) => {
                            return (
                                <Carousel.Item>
                                    <img width='100%' src={"/img/" + imgAltPair[0]} />                                    
                                </Carousel.Item>
                            )
                        }
                        )}
                    </Carousel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Col>        
    )
}




export default Figures;