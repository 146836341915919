import proj from './../Project.module.css';
import Figure from 'react-bootstrap/Figure';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Figures from '../../../Figure/Figures.js';


function AfricanStarlingFan() {
    return (
        <div>
            <div className={proj.subtitle}>Conservation</div>
            <div className={proj.subtext}>Feathers</div>
            <div><p className={proj.para}>The adhesive holding down the blue and green starling feathers onto the foundation feather started to deteriorate and the colourful feathers started to come off. In most areas, entire clusters of feathers are loose. Some of the feathers are detached.
                An initial surface clean removed dust from the feathers and furthermore, enabled a reshaping of distorted feathers and barbs. After that, all loose feathers were re-attached with protein glue.</p>
                <p className={proj.para}>
                    An initial surface clean removed dust from the feathers and furthermore, enabled a reshaping of distorted feathers and barbs. After that, all loose feathers were re-attached with protein glue.
                </p>
            </div>

            <div className={proj.subtext}>Ribbon</div>
            <div><p className={proj.para}>
                The maroon-coloured ribbon deteriorated and split in several areas It was not repairable. The remnants of the ribbon were carefully removed by softening the old adhesive with deionised water. In agreement with the owner, a green silk ribbon was chosen to replace the old one.
            </p>
            </div>

            <div className={proj.subtitle}>Images</div>

            <Container>
                <Row xs="1" xl="2" className="justify-content-center">
                    <Figures
                        images={
                            [["Starling/starling1.jpg", "Before conservation"],
                            ["Starling/starling2.jpg", "After conservation"]]
                        }
                        caption="Obverse of African starling feather fan before and after conservation"
                    />
                    <Figures
                        images={
                            [["Starling/starling3.jpg"],
                            ["Starling/starling4.jpg"]]
                        }
                        caption="Detached African starling feathers before conservation"
                    />
                    <Figures
                        images={
                            [["Starling/starling5.jpg", "Before conservation"],
                            ["Starling/starling6.jpg", "During conservation"]]
                        }
                        caption="Loose clusters of pheasant feathers before and during conservation"
                    />
                    <Figures
                        images={
                            [["Starling/starling7.jpg", "Before reshaping"],
                            ["Starling/starling8.jpg", "After reshaping"]]
                        }
                        caption="Stick 1 and 2 before and after reshaping, reintegration and securing of feathers"
                    />
                    <Figures
                        images={
                            [["Starling/starling9.jpg", "Before reshaping"],
                            ["Starling/starling10.jpg", "After reshaping"]]
                        }
                        caption="Stick 15 and 16 before and after reshaping, reintegration and securing of feathers"
                    />
                    <Figures
                        images={
                            [["Starling/starling11.jpg", "Before reshaping"],
                            ["Starling/starling12.jpg", "After reshaping"]]
                        }
                        caption="Stick 17 (guard on right hand side) before and after reshaping, reintegration and securing of feathers"
                    />
                </Row>
            </Container>

            <div className={proj.subtitle}>Video</div>

            <video className="w-100" loop muted controls>
                <source
                    src="/img/Starling/Starling fan - reribboning.mp4"
                    type="video/mp4"
                    allowFullScreen
                />
            </video>

        </div>
    );
}

export default AfricanStarlingFan;
