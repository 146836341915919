import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import title from './Title.module.css';
import { Link } from 'react-router-dom';


function Title() {
    return (
        <Container fluid>
            <Row xs={1} md={2}>
                <Col className="p-0">
                    <div id={title.myleft}>
                        <div id={title.titleabout} className="centre">
                            <h1>
                                <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}><span>ABOUT FANS &amp; FEATHERS</span></Link>
                            </h1>
                            <Link to="/portfolio" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="font_didot_20">
                                    View My Work
                                </div>
                            </Link>                            
                        </div>
                    </div>                
                </Col>
                <Col className="p-0">
                    <figure>
                        <img id={title.titleimage} src="/img/Starling/starling.jpg" alt="Fans &amp; Feathers"></img>
                    </figure>
                </Col>
            </Row>           
        </Container>
    );
}

export default Title;
